import {
	type MetaFunction,
	unstable_defineLoader as defineLoader,
	json,
} from '@remix-run/cloudflare'
import { Link, useLoaderData } from '@remix-run/react'
import axios from 'axios'
import { formatDistanceToNowStrict } from 'date-fns'
import { GeneralErrorBoundary } from '~/components/error-boundary'
import { Default_Post_Image } from '~/utils/misc'
import { useRequestInfo } from '~/utils/request-info'
import { SiteTitlet } from '~/utils/seo'
import { useOptionalUser } from '~/utils/user'

// --------------------------------------- LOADER --------------------------------------- //

export const loader = defineLoader(async ({ request, context }) => {
	const posts = await context.db.query.posts.findMany({
		columns: {
			id: true,
			title: true,
			content: true,
			createdAt: true,
			updatedAt: true,
		},
		with: {
			owner: true,
			featured_image: true,
			categories: {
				with: { category: true },
			},
		},
		orderBy: (posts, { desc }) => [desc(posts.createdAt)],
	})
	return json({ posts })
})

// --------------------------------------- COMPONENT --------------------------------------- //
export default function IndexHomePosts() {
	const data = useLoaderData<typeof loader>()

	return (
		<>
			<div className="grid grid-cols-1 gap-6 font-sans md:grid-cols-3 lg:grid-cols-4">
				{data.posts.map((post) => (
					<article key={post.id}>
						<div className="duration-300">
							<Link to={`/posts/${post.id}`} className="">
								<div>
									<img
										src={
											post.featured_image?.cloudinarySecureUrl ||
											Default_Post_Image
										}
										alt={post.title}
										className="h-40 w-full"
									/>
								</div>
							</Link>
							<div className="space-y-1.5">
								{post.categories[0].category && (
									<Link
										to={`/posts/${post.id}`}
										className="text-label2 uppercase text-destructive hover:underline"
									>
										{post.categories[0].category.name}
									</Link>
								)}
								<Link to={`/posts/${post.id}`}>
									<h3 className="line-clamp-3 pt-1 hover:text-red-400 hover:underline">
										{post.title}
									</h3>
								</Link>

								<div className="flex gap-1 text-note3">
									{post.owner ? (
										<>
											<Link to={`/users/${post.owner.username}`}>
												<p>{post.owner.username}</p>
											</Link>
											.
											<p>
												{formatDistanceToNowStrict(new Date(post.createdAt), {
													addSuffix: true,
												})}
											</p>
										</>
									) : (
										<p>
											{formatDistanceToNowStrict(new Date(post.createdAt), {
												addSuffix: true,
											})}
										</p>
									)}
								</div>
							</div>
						</div>
					</article>
				))}
			</div>
		</>
	)
}

// --------------------------------------- META --------------------------------------- //

export const meta: MetaFunction = () => {
	const site_title = SiteTitlet()
	return [{ title: site_title }, { name: 'description', content: 'Site desc' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
